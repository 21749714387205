@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Aldrich&family=Orbitron:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@layer base {
  html {
    scroll-behavior: smooth;
  }
}

body {
  background-image: url("../src/assets/Valorant_MainSide.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.secondary-font {
  font-family: "Aldrich", sans-serif;
}

.primary-font {
  font-family: "Orbitron", sans-serif;
}

td,
th {
  @apply p-2;
}
td {
  @apply border-t-2 border-solid border-[#674ea780];
}
tr {
  @apply hover:bg-[#af9ddd14] hover:text-[#ffffff];
}

td:first-child {
  border-left: none;
}
tr:last-child {
  border-left: none;
}

table {
  border-collapse: separate;
  border: solid #674ea7 2px;
  border-radius: 8px;
}

.sticky-div {
  position: sticky;
  bottom: 0;
  left: 50%;
  /* transform: translateX(-50%); */
  margin: 0 auto;
}

@keyframes anime-header {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 100%;
    transform: translateY(0px);
  }
}

/* The element to apply the animation to */
header,
footer {
  animation-name: anime-header;
  animation-duration: 0.5s;
}

@keyframes anime-main {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 100%;
    transform: translateX(0px);
  }
}

/* Add delay to the animation */
@keyframes delay-fade-in {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  /* Add a delay of 0.2s for each item */
  /* The delay is calculated based on the index of the item */
  /* The first item has a delay of 0s, the second has a delay of 0.2s, etc. */
  100% {
    opacity: 1;
    transform: translateY(0);
    animation-delay: calc(0.2s * var(--i));
  }
}

/* The element to apply the animation to */
.move-main {
  animation-name: anime-main;
  animation-duration: 1s;
}

@keyframes anime-game-details {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 100%;
    transform: translateX(0px);
  }
}

/* The element to apply the animation to */
.move-game-details {
  animation-name: anime-game-details;
  animation-duration: 0.5s;
}

.link {
  text-decoration: none;
  color: #a7a9be;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #434343;
  border-radius: 10px;
  position: relative;
  transition: 0.4s;
}

.link:hover {
  transform: rotate(45deg);
}

.link::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #373737;
  border-radius: 10px;
  transition: opacity 0.2s;
}

.link:hover::before {
  opacity: 0.4;
}

.link:nth-child(1)::before {
  background: #00acee;
}

.link:nth-child(2)::before {
  background: #ff0050;
}

.link:nth-child(4)::before {
  background: #0a66c2;
}

.link:hover::before {
  transform: translate(-4px, -4px);
  filter: blur(10px);
}

.nav:before {
  @apply content-[''] bg-[#BF9000] h-1 w-0 absolute left-0 -bottom-1 delay-150 duration-300;
}

.nav:hover::before {
  @apply w-full;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #191b2a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f9d25f52;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f9d25f;
}

.hover\:flex:hover + .hidden {
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

li {
  @apply ml-2 sm:ml-4;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}
